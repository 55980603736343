import React from 'react'
import map from '../../images/map.png'
import './styles.css'
import addToMailchimp from 'gatsby-plugin-mailchimp';
import Map from '../map';

class Contact extends React.Component{
  constructor(props){
    super(props);
    this.state={
      name: '',
      email:'',
      message:'',
      feedbackMsg:''
    }
  }

  encode = (data) => {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&")
  }

  handleChange = (e) => {
    this.setState({ ...this.state, [e.target.name]: e.target.value});
  }

  handleSubmit = async (e) => {
    e.preventDefault();
    e.target.reset();
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: this.encode({
        "form-name": "getintouch",
        ...this.state,
      }),
    })
    addToMailchimp(this.state.email,{
      "FNAME": this.state.name,
      "MESSAGE": this.state.message,
      "SUBJECT":"Contact us"
    })
    .then((response) => {
      console.log(response);
      this.setState({
        feedbackMsg: "Form submitted successfully!",
      })
    })
    .catch((error) => {
      console.log(error);
      this.setState({
        feedbackMsg: "Email already exists",
      })
    })
    console.log('Form submitted');
  }

  render(){
    return(
      <div className="contact">
        <div className="row">
          <div className="col-lg-4 mapDiv">
            {/* <div className="mapsubDiv"> */}
              <Map/>
            {/* </div> */}
          </div>
          <div className="col-lg-8 contactus" onSubmit={this.handleSubmit}>
          <div>
          <h1 className="contactheading">Connect With Us</h1>
          <p className="contactdesc">Want to partner with us or have a random question for us? Reach out to us with this simple form, and we will get back to you shortly.</p>
        </div>
            <form name="contactform" method="POST">
              <div className="contactinputDiv">
                <input className="contactinputname" type="text" placeholder="Name" name="name" id="NAME" onChange={this.handleChange} required/>
                <input className="contactemailInput" type="text" placeholder="Email Address*" name="email" id="email" onChange={this.handleChange} pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$" size="30" required/>
              </div>
              <textarea className="textarea" name="message" placeholder="Your Message *" onChange={this.handleChange} required/>
              <p className="contactfeedback">{this.state.feedbackMsg}</p>
              <button className="sendbutton">SEND MESSAGE</button>
            </form>
          </div>
        </div>
      </div>
    )
  }
}

export default Contact